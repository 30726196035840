<template>
	<template v-if="!underMaintenance">
		<header class="heading-section" v-if="checkForHeaderShow()">
			<HeaderComp @demo-login="this.$refs.loginModal.demoUserLogin();"></HeaderComp>
		</header>
	</template>
	<div data-bs-toggle="modal" data-bs-target="#notification-alert" ref="notifyMe">
	</div>
	<NotificationAlert @allowNotifications="allowNotifications" @cancelNotifications="cancelNotifications"/>
   	<NavBar></NavBar>

   <div>
      <router-view @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" @updateAuthToken="refreshAuthToken"/>
   </div>

   	<MobileFooter v-if="!underMaintenance"></MobileFooter>

	<Login @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" ref="loginModal"/>
	<SignUp @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
	<ForgetPassword @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />


	<div :class="{ show: showSuccessModal }" class="successfully-wrapper" style="z-index: 9999999999999999;" ref="successmodal" id="successfullyModal">
		<SuccessModal :message="successMsg"></SuccessModal>
	</div>

	<div :class="{ show: showErrorModal }" style="z-index: 9999999999999999;" class="successfully-wrapper" id="wrongtoaster">
		<ErrorModal :message="errorMsg"></ErrorModal>
	</div>

   	<MobileBetsMenu @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"  />
   	<StakeModal @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"  />

	<GamesPop1 @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
	<GamesPop2 @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />

   	<LanguageSelection @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />

	<Account v-if="checkIsLogin()" @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"/>
   	<LockedBonus @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
   	<ChatComponent @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"></ChatComponent>
	<div ref="click_logout_msg" @click="showErrorModalFunc('You are logout from this device.')" style="display: hidden;"></div>
</template>

<script>
import NotificationAlert from "./shared/components/modal/NotificationAlert.vue";
import { AUTH_TOKEN } from "./shared/constants/cookies-const";
import Loader from '@/shared/components/loader/Loader.vue';
import HeaderComp from '@/shared/components/header/Header.vue';
import NavBar from '@/shared/components/header/NavBar.vue';
import Banner from '@/shared/components/banner/Banner.vue';
import MobileFooter from '@/shared/components/footer/MobileFooter.vue';
import Login from '@/modules/authorization/components/Login.vue';
import SignUp from "./modules/authorization/components/SignUp.vue";
import ForgetPassword from "./modules/authorization/components/ForgetPassword.vue";
import SuccessModal from '@/shared/components/modal/SuccessModal.vue';
import ErrorModal from '@/shared/components/modal/ErrorModal.vue';
import MobileBetsMenu from "./shared/components/bets-menu/MobileBetsMenu.vue";
import ChatComponent from "./modules/chat/views/ChatComponent.vue";
import Maintenance from './shared/components/maintenance/Maintainence.vue'
import { setHeaders } from '@/shared/services/headers';
import { callFavouriteApis } from '@/shared/services/common-services';
import axios from 'axios';
import moment from "moment";
import { mapGetters } from 'vuex';

import api from '@/shared/services/api';
import * as apiName from '@/shared/services/urls';
import StakeModal from "./modules/account/components/StakeModal.vue";
import GamesPop1 from "./shared/components/modal/GamesPop1.vue";
import GamesPop2 from "./shared/components/modal/GamesPop2.vue";
import LanguageSelection from "./shared/components/header/LanguageSelection.vue";
import Translator from "vue-gtranslate";
import "vue-gtranslate/translator.css"
import Account from "./modules/account/components/Account.vue";
import socketService from "./socket/sport-socket-service";
import { provide,computed } from 'vue'
import LanguageService from '@/shared/services/language-service.js';
import { getWalletGetwayServiceCall } from "@/modules/wallet/services/wallet-service";

export default {
   name: 'App',
	data() {
		return {
			onLine: navigator.onLine,
			showBackOnline: false,
			loading: false,

			showSuccessModal: false,
			successMsg: "",
			showErrorModal: false,
			errorMsg: "",
			findActiveLang:"",
			allLanguages:null,
			langData: null,
			versionData:null,
			UpdatedVersion:null,
		}
	},
	provide() {
		return {
			translatedLangData: computed(() => this.translatedData)
		};
	},
	components: {
		Loader,
		HeaderComp,
		NavBar,
		MobileFooter,
		Banner,
		Login,
		ForgetPassword,
		SignUp,
		SuccessModal,
		ErrorModal,
		MobileBetsMenu,
		StakeModal,
		GamesPop1,
		GamesPop2,
		LanguageSelection,
		Translator,
		Account,
		NotificationAlert,
		ChatComponent,
		Maintenance
	},
	computed: {
		...mapGetters({
			eventDetailHideSections: 'eventDetailHideSections'
		}),
		routePath() {
			return this.$route.name;
		},
		underMaintenance() {
			return this.$store.getters.underMaintenance;
		},
		sitSettings() {
			return this.$store.getters.siteSettings;
			},
	},
	watch: {
		onLine(v) {
			if (v) {
				this.showBackOnline = true;
				setTimeout(() => {
					this.showBackOnline = false;
				}, 1000);
			}
		}
	},
	created() {
		let siteSettings = this.$store.getters.siteSettings;
		if (siteSettings?.front_css != null && siteSettings?.front_css != '') {// Create a style element
			const styleElement = document.createElement('style');
			styleElement.type = 'text/css';
			styleElement.id = 'custom-style'
			styleElement.appendChild(document.createTextNode(siteSettings.front_css));
			document.head.insertAdjacentElement('beforeend', styleElement);
		}
	},
  	async mounted() {
		//for use on js pages
		window.store = this.$store;
		window.cookies = this.$cookies;
		window.router = this.$router;
		window.emitLogoutMsg = this.$refs.click_logout_msg;
		window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
		
		//for add on all APIs
		axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
		setHeaders();
		if (this.checkIsLogin()) {
			callFavouriteApis();
			if(this.$store.getters.stateUser.notificationUnsubscribed==1){
				return;
			}
			if(this.$store.getters.stateUser.notificationSubscribed!=1) {
				this.requestNotificationPermission()
			}
		}
		await this.getSiteVersion();
		//checking internet connection
		window.addEventListener('online', this.updateOnlineStatus);
		window.addEventListener('offline', this.updateOnlineStatus);
		this.socketAllEvents();
   	},
	beforeDestroy() {
		window.removeEventListener('online', this.updateOnlineStatus);
		window.removeEventListener('offline', this.updateOnlineStatus);
	},
   	methods: {
		requestNotificationPermission() {
			if (!('Notification' in window)) {
				console.log('This browser does not support notifications.');
				return;
			}

			if (Notification.permission === 'granted') {				
				this.getSubscriptionData();
			} else if (Notification.permission !== 'denied') {
				this.openNotificationBox()
			}
		},
		openNotificationBox(){
			this.$refs.notifyMe.click()
		},
		allowNotifications(){
			Notification.requestPermission().then(permission => {
				if (permission === 'granted') {
					console.log('Notification permission granted.');
					this.getSubscriptionData();
				}
			});
		},
		cancelNotifications(){
			let user=this.$store.getters.stateUser;
			user.notificationUnsubscribed=1;
			this.$store.dispatch('setUser', user);
		},
		async getSubscriptionData(){
			let user=this.$store.getters.stateUser;
			user.notificationSubscribed=1;
			this.$store.dispatch('setUser', user);
			if ('serviceWorker' in navigator) {
				try {
					await navigator.serviceWorker.register('/service-worker.js').then(async registration => {

						setTimeout(async () => {
							if ('PushManager' in window) {
								if (registration.pushManager) {
									// Subscribe to push notifications
									const subscription = await registration.pushManager.subscribe({
										userVisibleOnly: true,
										applicationServerKey: process.env.VUE_APP_PUBLIC_VAPID_KEY
									});
									this.deviceData=subscription
									this.subscribeNotifications()
								} else {
									console.warn('PushManager is not available');
								}
							} else {
								console.warn('Push notifications are not supported');
							}
						}, 1000)

					}).catch(e => {
						console.log(e);
					});

					
				
				} catch (error) {
					console.error('Service Worker registration failed:', error);
				}
			} else {
				console.warn('Service workers are not supported in this browser.');
			}
		},
		subscribeNotifications(){
			let authToken = this.$cookies.get(AUTH_TOKEN);
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
			let data = {
					"deviceData": this.deviceData,
					"domainName": window.location.host,
					};
				api.post(apiName.SUBSCRIBE_NOTIFICATION, data, { headers }).then(response => {
					this.loadingSignUp = false;
					if (response) {
						console.log(response)
					}
				}).catch(error => {
					this.loadingSignUp = false;
					if (error)
						this.showErrorModalFunc(error[0]);
				});
		},
		translatedData(key, defaultVal) {
			if(this.langData?.[key]) {
				return this.langData?.[key];
			} else {
				return defaultVal;
			}
		},
		checkIsLogin() {
			return this.$store.getters.isAuthenticated;
		},

		async getSiteVersion() {
			await api.get(apiName.getVersionStatus + "?domain=" + window.location.hostname).then(async response => {
				if (response && response.status == 200) {
					if (response?.data?.status === 0) {
						this.showErrorModalFunc(response.data.debug[0]);
					} else {
						let resData = response?.data?.data;
						this.UpdatedVersion =  resData?.version;;
						this.versionData = resData;
						const storedVersion = this.siteVersion;
						if (!this.UpdatedVersion) {
             			 this.UpdatedVersion = '1'; 
						}
						if (!storedVersion || String(storedVersion) !== String(this.UpdatedVersion) || !this.sitSettings) {
							await this.getSiteSettingsServiceCall();
						}
						else {
							this.siteSettingCommon(this.sitSettings);
							}
						const fromDateTime = new Date(resData?.maintenance?.from_date).getTime();
						const toDateTime = new Date(resData?.maintenance?.to_date).getTime();
						const currentDateTime = Date.now(); 
						if (resData.maintenance != null && (fromDateTime && toDateTime && fromDateTime <= currentDateTime && currentDateTime <= toDateTime)) {
							this.$store.dispatch('setUnderMaintenance', resData.maintenance);
							this.$router.push({name:'Maintenance'});
							return;
						} else {
							if(this.underMaintenance) {
								this.$router.push({name:'Home'});
							}
						this.$store.dispatch('setUnderMaintenance', null); 
						}
					}
				}
			}).catch(error => {
				if (error) {
					this.showErrorModalFunc(error[0]);
				}
			});
		},

		
		async getSiteSettingsServiceCall() {
			this.loading = true;
			await api.get(apiName.v1_SITE_SETTINGS + "?domain=" + window.location.hostname).then(async response => {
				this.loading = false;
				if (response && response.status == 200) {
						if (response?.data?.status === 0) {
							this.showErrorModalFunc(response.data.debug[0]);
						} else {
							let resData = response?.data.data.site_settings
							let casino = response?.data.data?.casino
							this.$store.dispatch('setSettings', resData);
							this.$store.dispatch('setGamesData', casino);
							this.siteSettingCommon(resData);
							this.$store.dispatch('setLanguagesData', this.allLanguages);
						}
					}
					}).catch(error => {
						this.loading = false;
						if (error) {
						console.log("Error Login : ", error);
						this.$store.dispatch('setSiteVersion', null);
						this.showErrorModalFunc(error[0]);
						}
					});
		},

		async siteSettingCommon(siteSetting) {
			try {
				this.allLanguages = siteSetting.language_translate_list;
			if (this.allLanguages) {
				this.findActiveLang =this.allLanguages.find(lang => lang.lang_code === siteSetting.language_code);
				const selectedLanguageUrl =  localStorage.getItem('selectedLanguageUrl');
				if ((this.findActiveLang && this.findActiveLang.lang_url) || selectedLanguageUrl ) {
					const langCode = selectedLanguageUrl || this.findActiveLang.lang_url;
					const aws_url_language = siteSetting.aws_url+ '/' +langCode
					this.langData = await LanguageService.getLanguageTranslationKey(aws_url_language)
				}
			}
			this.setManifest(siteSetting);
			// Create FAVICON
			const favElement = document.createElement('link');
			favElement.rel = 'icon';
			favElement.href = siteSetting.aws_url + siteSetting.storage_path.domain_image + siteSetting.fav_logo;
			document.head.insertAdjacentElement('beforeend', favElement);

			const title = document.getElementById("title");
			title.text = siteSetting.domain_name;
			console.log(document.getElementById('custom-style'));
			try {
				if (siteSetting.front_css != null && siteSetting.front_css != '' && !document.getElementById('custom-style')) {// Create a style element
					const styleElement = document.createElement('style');
					styleElement.type = 'text/css';
					styleElement.appendChild(document.createTextNode(siteSetting.front_css));
					document.head.insertAdjacentElement('beforeend', styleElement);
					}
				}
				catch (error) {
					this.showErrorModalFunc(error);
				}
				if (this.checkIsLogin())
				this.getWalletBalance();
				this.$store.dispatch('setSiteVersion', this.UpdatedVersion);
			} catch (error) {
				this.$store.dispatch('setSiteVersion', null);				
				this.showErrorModalFunc(error);
			}
		},

		setManifest(resData) {
			let manifestName = resData.domain_name.charAt(0).toUpperCase() + resData.domain_name.slice(1).toLowerCase();
			var dynamicManifest = {
				name: manifestName,
				short_name: manifestName,
				start_url: window.location.origin,
				display: 'standalone',
				icons: [{
					src: resData.app_logo ? resData.aws_url + resData.storage_path.domain_image + resData.app_logo : resData.aws_url + resData.storage_path.domain_image + resData.fav_logo,
					sizes: "256x256 512x512 192x192 144x144",
					type: "image/png",
					purpose: 'any'
				}]
			}
			
			// Manifest Blob
			const stringManifest = JSON.stringify(dynamicManifest);
			const blob = new Blob([stringManifest], { type: 'application/json' });
			const manifestURL = URL.createObjectURL(blob);
			
			// Manifest Creation
			const favElement = document.createElement('link');
			favElement.rel = 'manifest';
			favElement.href = manifestURL;
			document.head.insertAdjacentElement('beforeend', favElement);
			
			// IOS APK Logo
			let logo = resData.aws_url + resData.storage_path.domain_image + resData.logo;
			document.querySelector('#appleIcon').setAttribute('href', logo);
			document.querySelector('#appleLaunchIcon').setAttribute('href', logo);

			// IOS App title
			const metaTag = document.createElement('meta');
			metaTag.setAttribute('name', 'apple-mobile-web-app-title');
			metaTag.setAttribute('content', manifestName);
			document.head.insertAdjacentElement('beforeend', metaTag);
		},
		updateOnlineStatus(e) {
			const {
				type
			} = e;
			this.onLine = type === 'online';
		},
		showErrorModalFunc(message) {
			this.errorMsg = message;
			this.showErrorModal = true;
			setTimeout(() => {
				this.showErrorModal = false;
			}, 3000);
		},
		showSuccessModalFunc(message) {
			this.successMsg = message;
			this.showSuccessModal = true;
			setTimeout(() => {
				this.showSuccessModal = false;
			}, 3000);
		},
		getWalletBalance() {
			let authToken = this.$cookies.get(AUTH_TOKEN);
			if (!authToken) return;
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
			api.post(apiName.WALLET_BALANCE, {}, { headers }).then(response => {
				if (response && response.status == 200 && response.data) {
					let user = this.$store.getters.stateUser;
					user.balance = response.data.bl;
					user.expl = response.data.ex;
					user.bets_count = response.data.bc;
					this.$store.dispatch('setUser', user);
					if ((user.exp - moment().utc().unix()) < 10) {
						this.refreshAuthToken(headers);
					} else {
						setTimeout(function () {
							this.getWalletBalance();
						}.bind(this), 2000);
					}
				} else {
					this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
					this.$store.dispatch('resetAllState');
				}
			}).catch(error => {
				if (error) {
					this.showErrorModalFunc(error[0]);
				}
			});
		},
		refreshAuthToken(bonus = false) {
				this.refreshInit = true;
				let authToken = this.$cookies.get(AUTH_TOKEN);
				let headers = {
					'Authorization': `Bearer ${authToken}`
				}
				api.post(apiName.AUTH_REFRESH, {}, { headers }).then(response => {
					let user = this.$store.getters.stateUser;
					user.token = response.data.data.token;
					user.exp = response.data.data.exp;
					this.$cookies.set(AUTH_TOKEN, response.data.data.token);
					this.$store.dispatch('setToken', `Bearer ${response.data.data.token}`);
					window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
					//for add on all APIs
					axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
					this.$store.dispatch('setUser', user);
					this.refreshInit = false;
					if(bonus) {
						router.go();
					}
				}).catch(error => {
					if (error) {
						this.showErrorModalFunc(error[0]);
					}
					this.refreshInit = false;
				});
			},
		socketAllEvents(){
			if(this.$store.getters.stateUser){
				socketService.emit("sub",'depositRequest-'+this.$store.getters.stateUser.userid);					
				socketService.emit("sub",'logout-'+this.$store.getters.stateUser.userid);					
				socketService.emit("sub",'banned-'+this.$store.getters.stateUser.userid);

				socketService.on("DepositRequest", (data) => {
					if (data.type =="myId") {
						if (data.notify_type == 1) {
							this.$store.dispatch('setNotification');
							this.showSuccessModalFunc('New Notification...');
						}
					}
				});
				socketService.on("logout", (data) => {
					if (data.type == "logout") {
						// logout user from users
						// if (data.notify_type == 1) {
						// 	this.$store.dispatch('setNotification');
						// 	this.showSuccessModalFunc('New Notification...')
						// }
					}
				});	
				socketService.on("banned", (data) => {
					if (data.type == "match_ban") {
						// match ban condition
					}else if(data.type == 'league_ban'){
						// league ban condition
					}
				});
			}			
		},
		checkForHeaderShow() {
			if (this.eventDetailHideSections && this.eventDetailHideSections.includes('show_full_screen') && this.routePath == 'sports-event-detail') {
				return false;
			} else {
				return true;
			}
		},
		getWalletGetway() {
            getWalletGetwayServiceCall();
        }
   	}
}
</script>

